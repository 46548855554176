import { portalName, topicName, hrefName } from "./Types";

export const tabs = [
  {
    name: topicName.new,
    id: Math.random().toString(16).slice(2),
    href: hrefName.newHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },

      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      {
        name: portalName.portfolio,
        id: Math.random().toString(16).slice(2),
        href: hrefName.portfolioHref,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.newHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      {
        name: portalName.hir444,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir444Href,
      },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
      {
        name: portalName.vilaggazdasag,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vilaggazdasagHref,
      },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
      {
        name: portalName.borsonline,
        id: Math.random().toString(16).slice(2),
        href: hrefName.borsonlineHref,
      },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.divany,
        id: Math.random().toString(16).slice(2),
        href: hrefName.divanyHref,
      },
      {
        name: portalName.femina,
        id: Math.random().toString(16).slice(2),
        href: hrefName.feminaHref,
      },
      {
        name: portalName.glamour,
        id: Math.random().toString(16).slice(2),
        href: hrefName.glamourHref,
      },
      // {
      //   name: portalName.kiskegyed,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.kiskegyedHref,
      // },
      {
        name: portalName.vezess,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vezessHref,
      },
    ],
  },
  {
    name: topicName.belfold,
    id: Math.random().toString(16).slice(2),
    href: hrefName.belfoldHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      {
        name: portalName.hir444,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir444Href,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.belfoldHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      {
        name: portalName.vilaggazdasag,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vilaggazdasagHref,
      },
    ],
  },
  {
    name: topicName.kulfold,
    id: Math.random().toString(16).slice(2),
    href: hrefName.kulfoldHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      {
        name: portalName.hir444,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir444Href,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.kulfoldHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },

      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      {
        name: portalName.portfolio,
        id: Math.random().toString(16).slice(2),
        href: hrefName.portfolioHref,
      },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
    ],
  },
  {
    name: topicName.tech,
    id: Math.random().toString(16).slice(2),
    href: hrefName.techHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.hwsw,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hwswHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
    ],
  },
  {
    name: topicName.economy,
    id: Math.random().toString(16).slice(2),
    href: hrefName.economyHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      {
        name: portalName.hir444,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir444Href,
      },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
      {
        name: portalName.vilaggazdasag,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vilaggazdasagHref,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      {
        name: portalName.portfolio,
        id: Math.random().toString(16).slice(2),
        href: hrefName.portfolioHref,
      },
      // {
      //   name: portalName.forbes,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.forbesHref,
      // },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
    ],
  },
  {
    name: topicName.business,
    id: Math.random().toString(16).slice(2),
    href: hrefName.businessHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      // {
      //   name: portalName.forbes,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.forbesHref,
      // },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      {
        name: portalName.portfolio,
        id: Math.random().toString(16).slice(2),
        href: hrefName.portfolioHref,
      },
      {
        name: portalName.vilaggazdasag,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vilaggazdasagHref,
      },
    ],
  },
  {
    name: topicName.auto,
    id: Math.random().toString(16).slice(2),
    href: hrefName.autoHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.vezess,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vezessHref,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      {
        name: portalName.vilaggazdasag,
        id: Math.random().toString(16).slice(2),
        href: hrefName.vilaggazdasagHref,
      },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
    ],
  },
  {
    name: topicName.culture,
    id: Math.random().toString(16).slice(2),
    href: hrefName.cultureHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.cultureHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
    ],
  },
  {
    name: topicName.sport,
    id: Math.random().toString(16).slice(2),
    href: hrefName.sportHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.nemzetisport,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nemzetisportHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.sportHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.index,
        id: Math.random().toString(16).slice(2),
        href: hrefName.indexHref,
      },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
      {
        name: portalName.borsonline,
        id: Math.random().toString(16).slice(2),
        href: hrefName.borsonlineHref,
      },
    ],
  },
  {
    name: topicName.aktualis,
    id: Math.random().toString(16).slice(2),
    href: hrefName.aktualisHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.aktualisHref,
      },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.divany,
        id: Math.random().toString(16).slice(2),
        href: hrefName.divanyHref,
      },
      {
        name: portalName.velvet,
        id: Math.random().toString(16).slice(2),
        href: hrefName.velvetHref,
      },
      {
        name: portalName.glamour,
        id: Math.random().toString(16).slice(2),
        href: hrefName.glamourHref,
      },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
      // {
      //   name: portalName.kiskegyed,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.kiskegyedHref,
      // },
      {
        name: portalName.borsonline,
        id: Math.random().toString(16).slice(2),
        href: hrefName.borsonlineHref,
      },
    ],
  },
  {
    name: topicName.life,
    id: Math.random().toString(16).slice(2),
    href: hrefName.lifeHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.hvg,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hvgHref,
      },
      {
        name: portalName.femina,
        id: Math.random().toString(16).slice(2),
        href: hrefName.feminaHref,
      },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.divany,
        id: Math.random().toString(16).slice(2),
        href: hrefName.divanyHref,
      },
      {
        name: portalName.glamour,
        id: Math.random().toString(16).slice(2),
        href: hrefName.glamourHref,
      },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
      {
        name: portalName.borsonline,
        id: Math.random().toString(16).slice(2),
        href: hrefName.borsonlineHref,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      // {
      //   name: portalName.kiskegyed,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.kiskegyedHref,
      // },
    ],
  },

  {
    name: topicName.bulvar,
    id: Math.random().toString(16).slice(2),
    href: hrefName.bulvarHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
      {
        name: portalName.hir24,
        id: Math.random().toString(16).slice(2),
        href: hrefName.hir24Href,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.bulvarHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.femina,
        id: Math.random().toString(16).slice(2),
        href: hrefName.feminaHref,
      },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.glamour,
        id: Math.random().toString(16).slice(2),
        href: hrefName.glamourHref,
      },
      {
        name: portalName.velvet,
        id: Math.random().toString(16).slice(2),
        href: hrefName.velvetHref,
      },
      {
        name: portalName.blikk,
        id: Math.random().toString(16).slice(2),
        href: hrefName.blikkHref,
      },
      {
        name: portalName.borsonline,
        id: Math.random().toString(16).slice(2),
        href: hrefName.borsonlineHref,
      },
      // {
      //   name: portalName.kiskegyed,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.kiskegyedHref,
      // },
    ],
  },
  {
    name: topicName.gastro,
    id: Math.random().toString(16).slice(2),
    href: hrefName.gastroHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.telex,
        id: Math.random().toString(16).slice(2),
        href: hrefName.telexHref,
      },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.femina,
        id: Math.random().toString(16).slice(2),
        href: hrefName.feminaHref,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   href: hrefName.origoHref,
      // },
    ],
  },
  {
    name: topicName.travel,
    id: Math.random().toString(16).slice(2),
    href: hrefName.travelHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.penzcentrum,
        id: Math.random().toString(16).slice(2),
        href: hrefName.penzcentrumHref,
      },
      // {
      //   name: portalName.origo,
      //   id: Math.random().toString(16).slice(2),
      //   component: (
      //     <NewsPost topic={hrefName.travelHref} portal={hrefName.origoHref} />
      //   ),
      //   href: hrefName.origoHref,
      // },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.velvet,
        id: Math.random().toString(16).slice(2),
        href: hrefName.velvetHref,
      },
      {
        name: portalName.divany,
        id: Math.random().toString(16).slice(2),
        href: hrefName.divanyHref,
      },
      {
        name: portalName.femina,
        id: Math.random().toString(16).slice(2),
        href: hrefName.feminaHref,
      },
    ],
  },
  {
    name: topicName.home,
    id: Math.random().toString(16).slice(2),
    href: hrefName.homeHref,
    items: [
      {
        name: topicName.all,
        id: Math.random().toString(16).slice(2),
        href: hrefName.allHref,
      },
      {
        name: portalName.nlc,
        id: Math.random().toString(16).slice(2),
        href: hrefName.nlcHref,
      },
      {
        name: portalName.femina,
        id: Math.random().toString(16).slice(2),
        href: hrefName.feminaHref,
      },
      {
        name: portalName.divany,
        id: Math.random().toString(16).slice(2),
        href: hrefName.divanyHref,
      },
    ],
  },
  {
    name: topicName.game,
    id: Math.random().toString(16).slice(2),
    href: hrefName.gameHref,
    items: [
      {
        name: portalName.sudoku,
        id: Math.random().toString(16).slice(2),
        href: hrefName.sudokuHref,
      },
      {
        name: portalName.memory,
        id: Math.random().toString(16).slice(2),
        href: hrefName.memoryHref,
      },
      {
        name: portalName.snake,
        id: Math.random().toString(16).slice(2),
        href: hrefName.snakeHref,
      },
    ],
  },
  // {
  //   name: topicName.video,
  //   id: Math.random().toString(16).slice(2),
  //   href: hrefName.videoHref,
  //   items: [
  //     {
  //       name: topicName.all,
  //       id: Math.random().toString(16).slice(2),
  //       href: hrefName.allHref,
  //     },
  //     {
  //       name: portalName.telex,
  //       id: Math.random().toString(16).slice(2),
  //       href: hrefName.telexHref,
  //     },
  //   ],
  // },
  // {
  //   name: topicName.weather,
  //   id: Math.random().toString(16).slice(2),
  //   href: hrefName.weatherHref,
  //   items: [
  //     {
  //       name: topicName.all,
  //       id: Math.random().toString(16).slice(2),
  //       component: <NewsPost topic={hrefName.weatherHref} />,
  //       href: hrefName.allHref,
  //     },
  //     {
  //       name: portalName.idokep,
  //       id: Math.random().toString(16).slice(2),
  //       component: (
  //         <NewsPost topic={hrefName.weatherHref} portal={hrefName.idokepHref} />
  //       ),
  //       href: hrefName.idokepHref,
  //     },
  //     {
  //       name: portalName.koponyeg,
  //       id: Math.random().toString(16).slice(2),
  //       component: (
  //         <NewsPost
  //           topic={hrefName.weatherHref}
  //           portal={hrefName.koponyegHref}
  //         />
  //       ),
  //       href: hrefName.koponyegHref,
  //     },
  //   ],
  // },
];
