export const portalName = {
  hvg: "HVG",
  telex: "Telex",
  hir24: "Hír24",
  penzcentrum: "Pénzcentrum",
  portfolio: "Portfolio",
  origo: "Origo",
  hir444: "444",
  index: "Index",
  hwsw: "HWSW",
  koponyeg: "Köpönyeg",
  idokep: "Időkép",
  divany: "Dívány",
  blikk: "Blikk",
  femina: "Femina",
  glamour: "Glamour",
  nlc: "Nlc",
  velvet: "Velvet",
  vilaggazdasag: "Világgazdaság",
  nemzetisport: "Nemzeti Sport",
  vezess: "Vezess",
  borsonline: "Bors",
  kiskegyed: "Kiskegyed",
  forbes: "Forbes",
  sudoku: "Sudoku",
  memory: "Memória",
  snake: "Snake"
};

export const topicName = {
  all: "Összes",
  new: "Friss",
  election: "EP választás 2024",
  belfold: "Belföld",
  kulfold: "Külföld",
  economy: "Gazdaság",
  tech: "Tech",
  life: "Életmód",
  gastro: "Gasztro",
  travel: "Utazás",
  agrar: "Agrár",
  trend: "Divat",
  home: "Otthon",
  family: "Család",
  bulvar: "Bulvár",
  weather: "Időjárás",
  culture: "Kultúra",
  sport: "Sport",
  auto: "Autó",
  aktualis: "Aktuális",
  business: "Vállalkozás",
  interesting: "Érdekesség",
  video: "Videó",
  game: "Játék",
}


export const hrefName = {
  newHref: "/friss",
  allHref: "/",
  belfoldHref: "/belfold",
  kulfoldHref: "/kulfold",
  economyHref: "/gazdasag",
  techHref: "/tech",
  bulvarHref: "/bulvar",
  weatherHref: "/idojaras",
  sportHref: "/sport",
  gastroHref: "/gasztro",
  lifeHref: "/eletmod",
  familyHref: "/csalad",
  freeTimeHref: "/szorakozas",
  trendHref: "/trend",
  agrarHfer: "/agrar",
  travelHref: "/utazas",
  homeHref: "/otthon",
  cultureHref: "/kultura",
  autoHref: "/auto",
  aktualisHref: "/aktualis",
  businessHref: "/business",
  erdekessegHref: "/erdekesseg",
  ingatlanbankHref: "/ingatlan-bank",
  videoHref: "/video",
  gameHref: "/jatek",

  hvgHref: "/hvg",
  telexHref: "/telex",
  hir24Href: "/hir24",
  penzcentrumHref: "/penzcentrum",
  portfolioHref: "/portfolio",
  origoHref: "/origo",
  hir444Href: "/444",
  indexHref: "/index",
  hwswHref: "/hwsw",
  koponyegHref: "/koponyeg",
  idokepHref: "/idokep",
  divanyHref: "/divany",
  blikkHref: "/blikk",
  feminaHref: "/femina",
  glamourHref: "/glamour",
  velvetHref: "/velvet",
  nlcHref: "/nlc",
  vilaggazdasagHref: "/vilaggazdasag",
  vezessHref: "/vezess",
  nemzetisportHref: "/nemzetisport",
  borsonlineHref: "/bors",
  kiskegyedHref: "/kiskegyed",
  forbesHref: "/forbes",
  sudokuHref: "/sudoku",
  memoryHref: "/memory",
  snakeHref: "/snake"
}