import {
  Flex,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Box,
  Text,
  Grid,
  GridItem,
  useMediaQuery,
  VStack,
  Card,
  IconButton,
  Skeleton,
  Stack,
  SkeletonText,
  Center,
  Image,
  // Button,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import reqmate from "reqmate";
import image from "../../assets/images/MagyarKrumpliLogobyDesigner-4.png";

import NewsBox from "../newsBox/NewsBox";
import { tabs } from "../utils/TabsItem";
import Authors from "../authors/Authors";
import { useEffect, useRef, useState } from "react";
import NewsPost, { NewsProps } from "../newsPost/NewsPost";
import SudokuGame from "../sudoku/SudokuGame";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Search from "../search/Search";
import Memory from "../memory/Memory";
import SnakeGame from "../snake/Snake";
import { getTopicName } from "../utils/utils";

const mainColor = "teal.400";
const baseURL = "https://api-dev.magyarkrumpli.hu";

const TopicNavbar = () => {
  const location = useLocation();
  const tabListRef = { tabs1: useRef(null), tabs2: useRef(null) };
  const [scrollLeftDisabled, setScrollLeftDisabled] = useState(true);
  const [scrollRightDisabled, setScrollRightDisabled] = useState(false);

  const [scrollSubLeftDisabled, setScrollSubLeftDisabled] = useState(true);
  const [scrollSubRightDisabled, setScrollSubRightDisabled] = useState(false);

  const [showSudokuGame, setShowSudokuGame] = useState(false);
  const [showMemoryGame, setShowMemoryGame] = useState(false);
  const [showSnakeGame, setShowSnakeGame] = useState(false);

  const [isLargerThan1100] = useMediaQuery("(min-width: 1100px)");

  const [entries, setEntries] = useState<NewsProps[] | undefined>();
  const [newsBox, setNewsBox] = useState<NewsProps[] | undefined>();

  const [search, setSearch] = useState(false);

  const path = location.pathname.split("/");
  const topic = !path[1] ? "/friss" : `/${path[1]}`;
  const portal = !path[2] ? "" : `/${path[2]}`;

  const [mainTab, setMainTab] = useState(topic);
  const [subTab, setSubTab] = useState(portal);
  const [hasMore, setHasMore] = useState(false);
  const [isHasMoreLoading, setIsHasMoreLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTabLoading, setIsTabLoading] = useState(false);

  const [page, setPage] = useState(1);
  const size = `?page=${page}&size=25`;

  function someMethodIThinkMightBeSlow() {
    const startTime = performance.now();
    const duration = performance.now() - startTime;
    console.info(duration);
  }
  setTimeout(someMethodIThinkMightBeSlow);

  useEffect(() => {
    const fetchMoreData = async () => {
      setIsHasMoreLoading(true);

      try {
        setIsLoading(true);
        const response = await reqmate
          .get(`${baseURL}${mainTab}${subTab}${size}`)
          .setCaching(500000)
          .send();
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res: any = response;
        if (Array.isArray(entries) && Array.isArray(res.data.results)) {
          const newItems = res.data.results.filter(
            (newItem) => !entries.some((item) => item.id === newItem.id)
          );
          setEntries([...entries, ...newItems]);
          setHasMore(res.data.hasMoreData);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsHasMoreLoading(false);
        setIsLoading(false);
      }
    };
    if (page > 1) {
      fetchMoreData();
    }
    setTimeout(someMethodIThinkMightBeSlow);
  }, [page]);

  useEffect(() => {
    const handleTabChange = async () => {
      setIsLoading(true);
      setIsTabLoading(true);
      setEntries([]);
      try {
        const url = `${baseURL}${mainTab}${size}`;
        const response = await reqmate.get(url).setCaching(500000).send();
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res: any = response;
        setNewsBox(res.data.results.slice(0, 10));
        setEntries(res.data.results);
        setHasMore(res.data.hasMoreData);
      } catch (error) {
        console.error("Error fetching main tab data:", error);
      } finally {
        setIsLoading(false);
        setIsTabLoading(false);
      }
    };
    handleTabChange();
    setTimeout(someMethodIThinkMightBeSlow);
  }, [mainTab]);

  useEffect(() => {
    const handleSubTabChange = async () => {
      setEntries([]);
      setIsTabLoading(true);
      setIsLoading(true);
      try {
        const url = `${baseURL}${mainTab}${subTab}${size}`;
        const response = await reqmate.get(url).setCaching(500000).send();
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res: any = response;
        setEntries(res.data.results);
        setHasMore(res.data.hasMoreData);
      } catch (error) {
        console.error("Error fetching sub-tab data:", error);
      } finally {
        setIsLoading(false);
        setIsTabLoading(false);
      }
    };
    handleSubTabChange();
    setTimeout(someMethodIThinkMightBeSlow);
  }, [subTab]);

  const handleScroll = () => {
    const scrollableWidth = tabListRef.tabs1.current.scrollWidth;
    const containerWidth = tabListRef.tabs1.current.clientWidth;
    const scrollLeft = tabListRef.tabs1.current.scrollLeft;

    if (scrollLeft === 0) {
      setScrollLeftDisabled(true);
    } else {
      setScrollLeftDisabled(false);
    }

    if (scrollableWidth - scrollLeft <= containerWidth) {
      setScrollRightDisabled(true);
    } else {
      setScrollRightDisabled(false);
    }
  };

  const scrollTabs = (scrollOffset) => {
    if (tabListRef.tabs1.current) {
      tabListRef.tabs1.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
      handleScroll();
      setTimeout(someMethodIThinkMightBeSlow);
    }
  };

  const handleSubScroll = () => {
    const scrollableWidth = tabListRef.tabs2.current.scrollWidth;
    const containerWidth = tabListRef.tabs2.current.clientWidth;
    const scrollLeft = tabListRef.tabs2.current.scrollLeft;

    if (scrollLeft === 0) {
      setScrollSubLeftDisabled(true);
    } else {
      setScrollSubLeftDisabled(false);
    }

    if (scrollableWidth - scrollLeft <= containerWidth) {
      setScrollSubRightDisabled(true);
    } else {
      setScrollSubRightDisabled(false);
    }
  };

  const scrollSubTabs = (scrollOffset) => {
    if (tabListRef.tabs2.current) {
      tabListRef.tabs2.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
      handleSubScroll();
      setTimeout(someMethodIThinkMightBeSlow);
    }
  };

  const dataLoading = () => {
    if (isLoading || isTabLoading) {
      return (
        <Stack pl="36px" pr="36px" pt="16px">
          {[1, 2, 3].map((item) => {
            return (
              <Box boxShadow="lg" bg="white" height="189px" p="18px" key={item}>
                <Grid templateColumns="repeat(9, 1fr)" gap={6}>
                  <GridItem colSpan={2}>
                    <Skeleton height="100%" />
                  </GridItem>
                  <GridItem colStart={3} colEnd={9}>
                    <SkeletonText
                      mt="1"
                      noOfLines={6}
                      spacing="4"
                      skeletonHeight="2"
                    />
                  </GridItem>
                </Grid>
              </Box>
            );
          })}
        </Stack>
      );
    }
    if (!isLoading && !isTabLoading && entries?.length === 0) {
      return (
        <Box p={20}>
          <Text as="b" fontSize={24}>
            Úgy látszik itt nincs semmi olvasni való, keressünk mást
          </Text>
          <Image h={150} w={150} src={image} />
        </Box>
      );
    }
  };

  return (
    <Flex
      align="center"
      pt={2}
      display={{ base: "none", sm: "none", md: "block", lg: "block" }}
      overflow="hidden"
    >
      <Search setSearch={setSearch} />
      {!search && (
        <Box padding={4}>
          <Tabs
            position="relative"
            defaultIndex={0}
            isLazy
            paddingLeft={12}
            pr={12}
          >
            <IconButton
              isDisabled={scrollLeftDisabled}
              onClick={() => scrollTabs(-100)}
              style={{ position: "absolute", left: 0 }}
              colorScheme="teal"
              aria-label="light arrow"
              isRound={true}
              icon={<ChevronLeftIcon />}
            />
            <TabList
              overflowX="scroll"
              borderColor={mainColor}
              sx={{
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              ref={tabListRef.tabs1}
              style={{
                display: "flex",
                flexWrap: "nowrap",
                padding: 0,
                margin: 0,
              }}
              onScroll={handleScroll}
            >
              {tabs.map((tab, mainIndex) => {
                return (
                  <Link to={tab.href} key={mainIndex}>
                    <Tab
                      mt={tab.href === "/olimpia" ? 0 : 4}
                      as="b"
                      _hover={{ transform: "scale(1.05)" }}
                      _selected={{
                        bg:
                          tab.href === "/olimpia"
                            ? "teal.50"
                            : tab.href === "/jatek"
                            ? "blue"
                            : mainColor,
                        textColor: "white",
                      }}
                      key={mainIndex}
                      onClick={() => {
                        if (tab.href === "/jatek") {
                          setShowSudokuGame(true);
                        } else {
                          setMainTab(tab.href);
                          setSubTab("");
                          setShowSudokuGame(false);
                          setShowMemoryGame(false);
                          setShowSnakeGame(false);
                        }
                      }}
                    >
                      {tab.name}
                    </Tab>
                  </Link>
                );
              })}
              <IconButton
                onClick={() => scrollTabs(100)}
                style={{ position: "absolute", right: 0 }}
                aria-label="right arrow"
                colorScheme="teal"
                isRound={true}
                isDisabled={scrollRightDisabled}
                icon={<ChevronRightIcon />}
              />
            </TabList>
            <Box marginLeft={12}>
              <Text as="b" fontSize="3xl" color={mainColor}>
                Pörgesd át a 10 legfrissebb {getTopicName(topic)} hírt!
              </Text>
            </Box>

            <NewsBox entries={newsBox} />

            <TabPanels>
              {tabs.map((tab, mainIndex) => {
                return (
                  <TabPanel key={mainIndex}>
                    <IconButton
                      isDisabled={scrollSubLeftDisabled}
                      onClick={() => scrollSubTabs(-100)}
                      style={{ position: "absolute", left: 0 }}
                      colorScheme="teal"
                      aria-label="light arrow"
                      isRound={true}
                      icon={<ChevronLeftIcon />}
                    />
                    <Tabs position="relative" defaultIndex={0} isLazy>
                      <TabList
                        marginLeft={12}
                        marginRight={12}
                        borderColor={mainColor}
                        overflowX="scroll"
                        sx={{
                          scrollbarWidth: "none",
                          "::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                        ref={tabListRef.tabs2}
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 0,
                          margin: 0,
                        }}
                        onScroll={handleSubScroll}
                      >
                        {tab.items.map((item, subIndex) => {
                          return (
                            <Link to={`${tab.href}${item.href}`} key={subIndex}>
                              <Tab
                                as="b"
                                _hover={{ transform: "scale(1.05)" }}
                                _selected={{
                                  bg: mainColor,
                                  textColor: "white",
                                }}
                                key={subIndex}
                                onClick={() => {
                                  if (item.href === "/sudoku") {
                                    setShowSudokuGame(true);
                                    setShowMemoryGame(false);
                                    setShowSnakeGame(false);
                                  } else if (item.href === "/memory") {
                                    setShowMemoryGame(true);
                                    setShowSudokuGame(false);
                                    setShowSnakeGame(false);
                                  } else if (item.href === "/snake") {
                                    setShowSnakeGame(true);
                                    setShowSudokuGame(false);
                                    setShowMemoryGame(false);
                                  } else {
                                    setSubTab(item.href);
                                    setMainTab(tab.href);
                                    setShowSudokuGame(false);
                                    setShowMemoryGame(false);
                                    setShowSnakeGame(false);
                                  }
                                }}
                              >
                                {item.name}
                              </Tab>
                            </Link>
                          );
                        })}
                        <IconButton
                          onClick={() => scrollSubTabs(100)}
                          style={{ position: "absolute", right: -55 }}
                          aria-label="right arrow"
                          colorScheme="teal"
                          isRound={true}
                          isDisabled={scrollSubRightDisabled}
                          icon={<ChevronRightIcon />}
                        />
                      </TabList>

                      <TabPanel p={0} pb={10}>
                        {isLargerThan1100 ? (
                          <>
                            {!search && (
                              <Grid
                                templateColumns="repeat(2, 75% 25%)"
                                gap={6}
                              >
                                {!showSudokuGame &&
                                  !showMemoryGame &&
                                  !showSnakeGame && (
                                    <GridItem>
                                      {dataLoading()}
                                      <NewsPost
                                        entries={entries}
                                        hasMore={hasMore}
                                        page={page}
                                        setPage={setPage}
                                        isHasMoreLoading={isHasMoreLoading}
                                      />
                                    </GridItem>
                                  )}

                                {showSudokuGame && (
                                  <GridItem justifySelf="center">
                                    <SudokuGame />
                                  </GridItem>
                                )}

                                {showMemoryGame && (
                                  <GridItem>
                                    <Memory size={8} />
                                  </GridItem>
                                )}

                                {showSnakeGame && (
                                  <GridItem>
                                    <SnakeGame />
                                  </GridItem>
                                )}

                                <GridItem pos="relative" mt="8px">
                                  <VStack spacing={6} align="stretch">
                                    <Card pt="34px" h="400px" pl="12px">
                                      <Authors author="idokep" />
                                      <a
                                        href="https://www.idokep.hu/"
                                        target="_blank"
                                        title="Időjárás"
                                      >
                                        <img
                                          src="//www.idokep.hu/terkep/hu_mini/i_anim.gif"
                                          alt="Felhőkép"
                                        />
                                      </a>
                                      <Text as="b" color={mainColor}>
                                        Felhőkép
                                      </Text>
                                      <a
                                        href="https://www.idokep.hu/"
                                        target="_blank"
                                        title="Időjárás"
                                      >
                                        <img
                                          src="//www.idokep.hu/terkep/hu_mini/hom_kozepes.jpg"
                                          alt="Hőtérkép"
                                        />
                                      </a>
                                      <Text as="b" color={mainColor}>
                                        Hőtérkép
                                      </Text>
                                      <Link
                                        target="blank"
                                        to="https://www.idokep.hu/"
                                      >
                                        <Text _hover={{ color: mainColor }}>
                                          További időjárási infókat az Időkép.hu
                                          oldalán ide kattintva találhatsz
                                        </Text>
                                      </Link>
                                    </Card>
                                    {!showSudokuGame &&
                                      !showMemoryGame &&
                                      !showSnakeGame && (
                                        <Box
                                          pt="18px"
                                          h="420"
                                          pl="2px"
                                          pb="8px"
                                        >
                                          <Text as="b" pb={4}>
                                            <span style={{ fontSize: "30px" }}>
                                              &#127918;
                                            </span>{" "}
                                            Gyere játssz egyet
                                          </Text>
                                          <SudokuGame />

                                          <Text>
                                            További játékért keresd a játék
                                            tabot fent ⬆️
                                          </Text>
                                        </Box>
                                      )}
                                  </VStack>
                                </GridItem>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <Box
                            display={{
                              base: "none",
                              sm: "none",
                              md: "block",
                              lg: "block",
                            }}
                          >
                            {!search && (
                              <>
                                {!showSudokuGame &&
                                  !showMemoryGame &&
                                  !showSnakeGame && (
                                    <>
                                      {dataLoading()}
                                      <NewsPost
                                        entries={entries}
                                        hasMore={hasMore}
                                        page={page}
                                        setPage={setPage}
                                        isHasMoreLoading={isHasMoreLoading}
                                      />
                                    </>
                                  )}
                                {showSudokuGame && (
                                  <Center>
                                    <SudokuGame />
                                  </Center>
                                )}

                                {showMemoryGame && <Memory size={8} />}

                                {showSnakeGame && <SnakeGame />}
                              </>
                            )}
                          </Box>
                        )}
                      </TabPanel>
                    </Tabs>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Flex>
  );
};

export default TopicNavbar;
