import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Image,
  Flex,
  Center,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { tabs } from "../utils/TabsItem";
import NewsPost, { NewsProps } from "../newsPost/NewsPost";
import { useEffect, useState } from "react";
import reqmate from "reqmate";
import SudokuGame from "../sudoku/SudokuGame";
import Search from "../search/Search";
import Memory from "../memory/Memory";
import SnakeGame from "../snake/Snake";

const mainDarkColor = "teal.600";
const mainColor = "teal.400";

const baseURL = "https://api-dev.magyarkrumpli.hu";

const MenuMobilView = ({ isOpen, onClose, btnRef, handleToggle }) => {
  const weekday = [
    "Vasárnap",
    "hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
  ];
  const day = new Date().getDay();
  const currDate = new Date().toISOString().slice(0, 10);
  const currentDay = weekday[day];

  const location = useLocation();

  const path = location.pathname.split("/");
  const topic = !path[1] ? "/friss" : `/${path[1]}`;
  const portal = !path[2] ? "/" : `/${path[2]}`;

  const [mainTab, setMainTab] = useState(topic);
  const [subTab, setSubTab] = useState(portal);

  const [entries, setEntries] = useState<NewsProps[] | undefined>();
  const [newsBox, setNewsBox] = useState<NewsProps[] | undefined>();
  const [hasMore, setHasMore] = useState(false);
  const [isHasMoreLoading, setIsHasMoreLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSudokuGame, setShowSudokuGame] = useState(false);
  const [showMemoryGame, setShowMemoryGame] = useState(false);
  const [showSnakeGame, setShowSnakeGame] = useState(false);
  const [page, setPage] = useState(1);
  const size = `?page=${page}&size=25`;

  const [search, setSearch] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const fetcFirstData = async () => {
      try {
        const response = await reqmate
          .get(`${baseURL}${mainTab}${subTab}${size}`)
          .setCaching(500000)
          .send();
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res: any = response;
        setEntries(res.data.results);
        setHasMore(res.data.hasMoreData);
        setNewsBox(res.data.results.slice(0, 10));
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetcFirstData();
  }, []);

  useEffect(() => {
    const fetchMoreData = async () => {
      setIsHasMoreLoading(true);
      try {
        const response = await reqmate
          .get(`${baseURL}${mainTab}${subTab}${size}`)
          .setCaching(500000)
          .send();
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res: any = response;
        if (Array.isArray(entries) && Array.isArray(res.data.results)) {
          const newItems = res.data.results.filter(
            (newItem) => !entries.some((item) => item.id === newItem.id)
          );
          setEntries([...entries, ...newItems]);
          setHasMore(res.data.hasMoreData);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsHasMoreLoading(false);
      }
    };
    fetchMoreData();
  }, [page]);

  const handleSubTabChange = async (mainTab, subTab) => {
    setIsLoading(true);
    setEntries([]);
    try {
      const url = `${baseURL}${mainTab.href}${subTab.href}${size}`;
      const response = await reqmate.get(url).setCaching(300000).send();
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const res: any = response;
      setEntries(res.data.results);
      setHasMore(res.data.hasMoreData);
    } catch (error) {
      console.error("Error fetching sub-tab data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = async (mainTab) => {
    setIsLoading(true);
    setEntries([]);
    try {
      const url = `${baseURL}${mainTab.href}${size}`;
      const response = await reqmate.get(url).setCaching(300000).send();
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const res: any = response;
      setNewsBox(res.data.results.slice(0, 10));
    } catch (error) {
      console.error("Error fetching sub-tab data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg={mainColor}>
          <DrawerCloseButton color="white" />
          <DrawerHeader pt={6}>
            <Flex justifyItems="center">
              <Image
                src="/MagyarKrumpliLogobyDesigner-2.png"
                boxSize="50px"
                alt="test image"
              />
              <Box pt={2} pl={2}>
                <Text fontSize={14} color="white" as="b">
                  {`${format(currDate, "yyyy.MM.dd.")} `}
                </Text>
                <Text fontSize={14} color="white" as="b">
                  {currentDay}
                </Text>
              </Box>
            </Flex>
            <Text fontSize={18} color="white">
              Válassz témát és híroldalt
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    textColor="white"
                    as="b"
                    _hover={{ bg: mainDarkColor }}
                    cursor="pointer"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <span style={{ fontSize: "30px" }}>&#127918; </span>
                      Kikapcsolódnál? Játssz egyet{" "}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel
                  pb={4}
                  pl={8}
                  _hover={{ bg: mainDarkColor }}
                  cursor="pointer"
                  onClick={() => {
                    setShowSudokuGame(true);
                    setShowMemoryGame(false);
                    setShowSnakeGame(false);
                    handleToggle();
                  }}
                >
                  <Text as="b" textColor="white">
                    Sudoku
                  </Text>
                </AccordionPanel>
                <AccordionPanel
                  pb={4}
                  pl={8}
                  _hover={{ bg: mainDarkColor }}
                  cursor="pointer"
                  onClick={() => {
                    setShowMemoryGame(true);
                    setShowSudokuGame(false);
                    setShowSnakeGame(false);
                    handleToggle();
                  }}
                >
                  <Text as="b" textColor="white">
                    Memória
                  </Text>
                </AccordionPanel>
                <AccordionPanel
                  pb={4}
                  pl={8}
                  _hover={{ bg: mainDarkColor }}
                  cursor="pointer"
                  onClick={() => {
                    setShowSnakeGame(true);
                    setShowMemoryGame(false);
                    setShowSudokuGame(false);
                    handleToggle();
                  }}
                >
                  <Text as="b" textColor="white">
                    Snake
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              {tabs.map((tab) => {
                return (
                  <AccordionItem key={tab.id}>
                    <h2>
                      <AccordionButton
                        _hover={{ bg: mainDarkColor }}
                        color="white"
                        onClick={() => {
                          setMainTab(tab.href);
                          setSubTab("");
                        }}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          <Text textColor="white" as="b">
                            {tab.name}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>

                    {tab.items.map((item) => {
                      return (
                        <Link to={`${tab.href}${item.href}`} key={item.id}>
                          <AccordionPanel
                            pb={4}
                            pl={8}
                            _hover={{ bg: mainDarkColor }}
                            onClick={() => {
                              handleSubTabChange(tab, item);
                              handleTabChange(tab);
                              setShowSudokuGame(false);
                              setShowMemoryGame(false);
                              setShowSnakeGame(false);
                              setSubTab(item.href);
                              setMainTab(tab.href);
                              handleToggle();
                            }}
                            cursor="pointer"
                          >
                            <Text as="b" textColor="white">
                              {item.name}
                            </Text>
                          </AccordionPanel>
                        </Link>
                      );
                    })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {showSudokuGame ? (
        <Center m={4}>
          <SudokuGame />
        </Center>
      ) : showMemoryGame ? (
        <Box m={4}>
          <Memory size={6} />
        </Box>
      ) : showSnakeGame ? (
        <SnakeGame />
      ) : (
        <>
          <Search setSearch={setSearch} />
          {!search && (
            <NewsPost
              entries={entries}
              hasMore={hasMore}
              page={page}
              setPage={setPage}
              isHasMoreLoading={isHasMoreLoading}
              topic={topic}
              isLoading={isLoading}
              newsBox={newsBox}
            />
          )}
        </>
      )}
    </>
  );
};

export default MenuMobilView;
